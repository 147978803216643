import { graphql, Link, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import PortfolioItem from '../components/portfolio-item';
import PortfolioSection from '../components/portfolio-section';

const AngebotePage = (props) => {
  const title = 'Angebote';

  const { images } = useStaticQuery(
    graphql`
      {
        images: allFile(filter: { relativeDirectory: { eq: "angebote" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Unser Angebot richtet sich ganz und gar nach deinen Wünschen. Deine individuellen Bedürfnisse motivieren uns stets zu Bestleistungen für dein neues Wohlfühl-Ich."
      />

      <IntroText title={title}>
        Unser Angebot richtet sich ganz und gar nach deinen Wünschen. Ob du die
        Spitzen schneiden möchtest, ein komplettes Umstyling im Auge hast, eine
        ausgefallene Frisur für einen speziellen Anlass oder ein ganz anderes
        Verwöhnprogramm wünschst: Deine individuellen Bedürfnisse motivieren uns
        stets zu Bestleistungen für dein neues Wohlfühl-Ich - ganz nach dem
        Motto "Schön wie ein Schmetterling!"
      </IntroText>

      <PortfolioSection>
        <PortfolioItem
          title="Hair"
          image={getImage(
            images.edges.find((e) => e.node.name === 'hair').node
          )}
        >
          <p>
            Egal ob ein klassischer Haarschnitt, eine schöne Hochsteck-Frisur
            oder einfach einmal etwas Neues: Wir setzten deine Wünsche gerne in
            die Tat um oder beraten dich gerne zu neuen Trends.
          </p>
          <p>
            Wir vertrauen auf die Produkte von Joico. Mit ihrer Technologie{' '}
            <i>Bio-Advanced Peptide Complex™</i> wird das ganze Haar optimal
            gepflegt und sogar beschädigtes Haar wieder repariert. Mit der
            grossen Auswahl an verschiedenen Produkten gibt es für jedes Haar
            die richtige Pflege.
            <br />
            <br />
            <a
              href="https://www.joico.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-external-link"></i> www.joico.com
            </a>
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Make-up"
          image={getImage(
            images.edges.find((e) => e.node.name === 'make-up').node
          )}
          right={true}
        >
          <p>
            Als Visagistinnen holen wir das Beste aus dir heraus, ganz egal ob
            es sich um ein einfaches Tages Make-up oder um einen Gala-Abend
            handelt. Wir sind auch ausserhalb des Salons verfügbar (z.B. für
            spezielle Anlässe oder Hochzeiten).
          </p>
          <p>
            Für unsere Make-ups verwenden wir die Produkte von Alcina. Alcina
            ist eine exklusive Hair and Beauty-Marke, die deutsche Qualität und
            internationale Trends perfekt kombiniert und Fachleuten aus den
            Bereichen Coiffeur, Schönheit und Make-up hochwertige Produkte
            bietet.
            <br />
            <br />
            <a
              href="https://www.alcina.ch"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-external-link"></i> www.alcina.ch
            </a>
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Hochzeiten"
          image={getImage(
            images.edges.find((e) => e.node.name === 'hochzeiten').node
          )}
        >
          <p>
            Für den schönsten Tag im Leben dürfen ein passendes Make-up sowie
            eine wunderschöne Frisur nicht fehlen -langanhaltend und wasserfest
            natürlich. Mit einem persönlichen Kennenlerngespräch und einem
            Probetermin stellen wir sicher, dass du dich jederzeit gut
            aufgehoben und in besten Händen fühlst.
          </p>
          <p>
            Wenn gewünscht, begleiten wir dich gerne an deine Hochzeit oder
            kommen vor Ort, um deinen Tag so schön und unkompliziert wie möglich
            zu gestalten.
          </p>
          <p>
            Für ein unverbindliches Angebot kannst du uns jederzeit{' '}
            <Link to="/kontakt/">kontaktieren</Link>.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Produkte"
          image={getImage(
            images.edges.find((e) => e.node.name === 'produkte').node
          )}
          right={true}
        >
          <p>
            Alle Produkte, die wir im Salon anwenden, kannst du direkt vor Ort
            beziehen. Natürlich können wir auch zusätzliche Produkte von unseren
            Partnern für dich besorgen. Sprich uns bei deinem nächsten Termin
            einfach darauf an.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Gutscheine"
          image={getImage(
            images.edges.find((e) => e.node.name === 'gutscheine').node
          )}
        >
          <p>
            Gutscheine können bei uns für jeden beliebigen Betrag bezogen
            werden. Sie sind für sämtliche Angebote und Produkte gültig.
            Selbstverständlich kannst du deine Bestellung auch{' '}
            <Link to="/kontakt/">online ausführen</Link>. Bitte teile uns die
            Adresse und den Betrag mit, so dass der Gutschein schnellstmöglich
            versendet werden kann.
          </p>
        </PortfolioItem>

        <PortfolioItem
          title="Spezielle Wünsche?"
          image={getImage(
            images.edges.find((e) => e.node.name === 'spezial').node
          )}
          right={true}
        >
          <p className="mb-0">
            Hast du eine ganz konkrete Vorstellung für deine Haare oder ein
            Make-up, welches du auch gerne einmal ausprobieren möchtest? Zögere
            nicht, uns zu <Link to="/kontakt/">kontaktieren</Link>.
          </p>
        </PortfolioItem>
      </PortfolioSection>
    </Layout>
  );
};

export default AngebotePage;
